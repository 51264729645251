export const images = [
    {picture: "./7.jpg", date: 1799915600000, info: 'Приветствуем Вас, друзья!!  У нас хорошие новости для всех любителей электронной музыки!!! \n' +
            '\n' +
            'SeemSeem и DJ школа Format открывают двери для тех, кто давно мечтал оказаться по другую сторону пульта.\n' +
            'Программа обучения подойдёт тем, кто совсем не обладает ни какими знаниями и тем, кто уже делает первые шаги в диджеинге, но чувствует, что нуждается прокачке своих навыков.\n' +
            'Записаться на собеседование и узнать подробности можно написав нам в telegram @SeemSeemMusic', poster: 4.5},
    {picture: "./1.jpg", date: 1737129600000, info: 'Приглашение на вечеринку в клубе SeemSeem!\n' +
            '\n' +
            'Дорогие друзья!\n' +
            '\n' +
            'Приглашаем вас на незабываемую вечеринку в ночном клубе SeemSeem, которая состоится 17 января 2025 Начало в 23:00. \n' +
            '\n' +
            '23-01 - Chu\n' +
            '01-03 - Nagval \n' +
            '03-05 - Dasha Weiss \n' +
            '05-07 - leXyCom \n' +
            '\n' +
            'NAGVAL - Резидент клуба SEEM SEEM.  Роман 32 года. музыкой занимаюсь еще со школы, профессионально начал подходить к этому относительно недавно. Пишу музыку в разных стилях от indi house, afro, thec и т.д. Выпускаюсь на лэйбле "AREY" . Занимаюсь музыкой 2 года, за это время успел принять участие на сибирских фестивалях INDIA и Playa. Часто принимаю участие в организации вечеринок, и привозом известных диджеев.\n' +
            '\n' +
            'Не упустите возможность провести этот вечер в компании хороших друзей и отличной музыки.\n' +
            '\n' +
            '📅 Дата: пятница, 17 января 2025года  \n' +
            '🕗 Время: 23:00  \n' +
            '📍 Место: Ночной клуб SeemSeem, Фрунзе, 5\n' +
            '🚪 Вход: \n' +
            '💃Девочки - free \n' +
            '🕺Парни - 500-1000₽', poster: './1.jpg'},
    {picture: "./2.jpg", date: 1737216000000, info: 'Приглашение на вечеринку в клубе SeemSeem!\n' +
            '\n' +
            'Дорогие друзья!\n' +
            '\n' +
            'Не упустите возможность провести этот вечер в компании хороших друзей и отличной музыки.\n' +
            '\n' +
            '23-01 - Tim\n' +
            '01-03 - Apris\n' +
            '03-05 - Leronaxx \n' +
            '05-07 - Shnaider\n' +
            '\n' +
            '📅 Дата: суббота, 18 января 2025года  \n' +
            '🕗 Время: 23:00  \n' +
            '📍 Место: Ночной клуб SeemSeem, Фрунзе, 5\n' +
            '🚪 Вход: \n' +
            '💃Девочки - free \n' +
            '🕺Парни - 500-1000₽', poster: './2.jpg'},
    {picture: "./3.jpg", date: 1737734400000, info: 'Дорогие друзья!\n' +
            '\n' +
            'Не упустите возможность провести этот вечер в компании хороших друзей и отличной музыки.\n' +
            '\n' +
            '23-1 - Vivex (vinyl set)\n' +
            '1-3 - Tim\n' +
            '3-5 - Apris \n' +
            '5-7 - Alex Dav \n' +
            '\n' +
            '📅 Дата: пятница , 24 января 2025.   \n' +
            '🕗 Время: 23:00  \n' +
            '📍 Место: Ночной клуб SeemSeem, Фрунзе, 5\n' +
            '🚪 Вход: \n' +
            '💃Девочки - free \n' +
            '🕺Парни - 500-1000₽', poster: './3.jpg'},
    {picture: "./4.jpg", date: 1737734400000, info: '25 января в SeemSeem наши резиденты и специальные гости: \n' +
            'Xxanych - начал обучение диджеингу в 12 лет и по сей день зажигает танцпол ритмичными треками. Его стиль - это хаус, погружающий в хорошее настроение.\n' +
            'GADI - выпускница DJ школы Syncabeat и резидент клуба Seem Seem. Ее космическое звучание завораживает толпу, а треки пробирают до мурашек. Играет качественное звучание в стилях Afro house, Progressive, Indie dance, Deep house и Melodic techno.\n' +
            '\n' +
            '23-1 - LeXyCom \n' +
            '1-3 - Gadi&Xxanych\n' +
            '3-5 - Mawi \n' +
            '5-7 - Chu\n' +
            '\n' +
            '📅 Дата: суббота, 25 января 2025.   \n' +
            '🕗 Время: 23:00  \n' +
            '📍 Место: Ночной клуб SeemSeem, Фрунзе, 5\n' +
            '🚪 Вход: \n' +
            '💃Девочки - free \n' +
            '🕺Парни - 500-1000₽', poster: './4.jpg'},

];
